import React from "react";
import { View, Text } from "react-native";
import { useTailwind } from "tailwind-rn";

const UserMessagingScreen = ({}) => {
  const tw = useTailwind();

  return (
    <Text>UserMessagingScreen</Text>
  );
};

export default UserMessagingScreen;