export const suspectsImagesMapping = {
        1: require('images/suspects/suspect_identification_1.png'),
        2: require('images/suspects/suspect_identification_2.png'),
        3: require('images/suspects/suspect_identification_3.png'),
        4: require('images/suspects/suspect_identification_4.png'),
        5: require('images/suspects/suspect_identification_5.png'),
        6: require('images/suspects/suspect_identification_6.png'),
        7: require('images/suspects/suspect_identification_7.png'),
        8: require('images/suspects/suspect_identification_8.png'),
        9: require('images/suspects/suspect_identification_9.png'),
};